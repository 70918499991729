@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "nunito";
  src: url("./assets/fonts/Abel-Regular.ttf") format("truetype");
}

body,p,a {
  font-family: "nunito" !important;
}

a {
  text-decoration: none;
}

.main-container .container {
  min-height: 100%;
  margin: 0 1rem;
}

.top-banner {
  height: 100vh;
}

.sectionContainer {
  padding: 1rem 0;
}

.footer {
  background-image: url("https://res.cloudinary.com/dkzkb69pj/image/upload/v1675229771/puliclean/beautiful-shot-modern-style-open-space-office-interior-min_2_n7t6s9.jpg");
  background-size: cover;
  background-blend-mode: multiply;
  background-color: #00000080;
  padding: 1rem 0;
}

@media screen and (min-width: 762px) {
  .main-container .container {
    margin: 0 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .main-container .container {
    margin: 0 auto;
    max-width: 1024px;
  }
}
