.videoContainer {
    overflow: hidden;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.videoContainer .mainContactContainer {
    z-index: 1;
    margin: 0 2rem;
    text-align: center;
}

.videoContainer .mainContactContainer .mainContact {
    background-color: transparent;
    width: 75%;
    padding: 1rem;
    border: 0.125rem solid white;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0.0205rem 0.0205rem 0.0205rem 0.0205rem black;
}

.videoContainer .mainContactContainer h1 {
    font-size: 2.4rem;
    font-family: "nunito";
    color: white;
    text-shadow: 0.0625rem 0.0625rem 0.0625rem black;
}

.videoContainer video {
    top: 0;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    object-fit: cover;
    height: 100vh;
}


.topBannerContainer {
    width: 100%;
    height: 100vh;
    box-shadow: 0px 3px 8px #1563c0;
}

.topBannerContainer .bannerInfo {
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,0.3);
}

.innerBannerInfo {
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;
}

.innerBannerInfo a {
    display: flex;
}

.innerBannerInfo a img {
    width: 7rem;
}

.rightData {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.8rem;
    margin: auto 0;
}

.rightData a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    text-align: right;
    font-weight: 600;
    line-height: 1.7rem;
}

.mainContact a {
    font-weight: 600;
    color: white;
}

.mainContact Link {
    font-weight: 600;
}


@media screen and (min-width: 768px) {
    .innerBannerInfo {
        margin: 0 2rem;
        max-width: 1024px;
    }
    .innerBannerInfo a .mainLogo {
        width: 8.4rem;
    }

    .rightData {
        font-size: 1.05rem;
    }

    .videoContainer .mainContactContainer {
        text-align: left;
        max-width: 1024px;
        margin: 0 2rem;
    }

    .videoContainer .mainContactContainer h1 {
        font-size: 3.8rem;
    }

    .mainContact {
        max-width: 22rem;
    }
}

@media screen and (min-width: 1024px) {
    .innerBannerInfo {
        margin: 0 auto;
    }

    .mainContactContainer {
        margin: 0 2rem;
    }
}
